// страница обратной связи

import emailjs from "@emailjs/browser";
import { useEffect, useState } from "react";
import { Spin, Result, Modal } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import { PageHeader, Card, Avatar } from "antd";
import VideoContentYT from "react-video-content-youtube";
import { Tag } from "antd";
import {
  HeartOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

const InfoPage = () => {
  const { Meta } = Card;
  const [spinner, setSpinner] = useState(false);
  const [comments, setComments] = useState([]);
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const phpURL = "https://followmytrack.ru/comment.php";

  useEffect(() => {
    readFromDb();
  }, []);

  /* eslint-disable no-template-curly-in-string */

  const validateMessages = {
    required: "${label} обязательно!",
    types: {
      phone: "${label} is not a valid phone!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const sendFeedBack = async () => {
    setSpinner(true);
    const name = document.forms[0].elements[0].value;
    const date = new Date().toLocaleString("ru", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timezone: "UTC",
      hour: "numeric",
      minute: "numeric",
    });
    const comment = document.forms[0].elements[1].value;
    console.log(name, date, comment);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("date", date);
    formdata.append("comment", comment);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/comment.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    setSpinner(false);
    readFromDb();
  };

  const readFromDb = async () => {
    setSpinner(true);
    try {
      const response = await fetch(phpURL);
      const result = await response.json();
      setComments(result);
      console.log("Comments Dowmloaded", result);
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
    setSpinner(false);
  };

  return (
    <>
      {spinner ? (
        <>
          <Spin /> <span> </span> Отправка сообщения ...{" "}
        </>
      ) : (
        <>
          {/* <Card>
            <form
              enctype="multipart/form-data"
              action="https://followmytrack.ru/uploadFile.php"
              method="POST"
            >
              <input type="hidden" name="MAX_FILE_SIZE" value="300000" />
              Отправить этот файл: <input name="userfile" type="file" />
              <input type="submit" value="Отправить файл" />
            </form>
          </Card> */}
          <Form
            {...layout}
            style={{
              borderRadius: "5px",
              boxShadow: "2px 2px 8px 2px #888888",
              borderStyle: "solid",
              borderColor: "black",
              paddingRight: "15px",
              paddingLeft: "10px",
              paddingTop: "25px",
              backgroundColor: "#f0f8fc",
            }}
            name="nest-messages"
            onFinish={sendFeedBack}
            validateMessages={validateMessages}
          >
            <b>
              <Form.Item
                name={["user", "name"]}
                label="Имя"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </b>
            <br />
            {/* <b>
              <Form.Item>
                <form
                  enctype="multipart/form-data"
                  action="https://followmytrack.ru/test-script/upload2.php"
                  method="POST"
                >
                  <input type="hidden" name="MAX_FILE_SIZE" value="30000" />
                  Отправить файл: <input name="userfile" type="file" />
                  <input type="submit" value="Отправить файл" />
                </form>
              </Form.Item>
            </b> */}

            <Form.Item name={["user", "introduction"]} label="Сообщение">
              <Input.TextArea />
            </Form.Item>
            <br />
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button icon={<SendOutlined />} type="primary" htmlType="submit">
                Отправить
              </Button>
            </Form.Item>
            <br />
          </Form>

          <br />

          {comments &&
            comments.map((el) => (
              <>
                <Card
                  title={
                    <Meta
                      title={el.name}
                      style={{
                        "font-size": "small",
                      }}
                      description={
                        <>
                          <Tag color="red">Пещеры</Tag> {el.date}
                        </>
                      }
                    />
                  }
                >
                  <p>{el.comment}</p>
                </Card>
                <br />
              </>
            ))}
        </>
      )}
    </>
  );
};

export default InfoPage;
